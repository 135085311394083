<script setup lang="ts">
import { useReferrals } from "@/api/referrals/use-referrals";
import TheSidebar from "@/components/shell/TheSidebar.vue";
import { useUserStore } from "@/stores/user";
import { BaseButton, BaseIcon } from "@magma-app/magma-lapilli";
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import { RouterLink } from "vue-router";

const { data } = useReferrals();
const userStore = useUserStore();
const { t } = useI18n();

const tabs = computed(() => {
  return [
    {
      label: `${t("referral.invitations.pending")} (${
        data.value?.referrals.pending.length ?? 0
      })`,
      value: "pending",
    },
    {
      label: `${t("referral.invitations.confirmed")} (${
        data.value?.referrals.confirmed.length ?? 0
      })`,
      value: "confirmed",
    },
  ];
});

const currentTab = ref<"pending" | "confirmed">("pending");
</script>

<template>
  <div class="flex bg-background-subtle">
    <TheSidebar />
    <div
      class="w-full flex-col bg-background-default lg:ml-20 lg:mt-3 lg:rounded-tl-[32px] lg:border lg:border-border-subtle lg:border-b-transparent 2xl:ml-64"
    >
      <div class="my-6 lg:my-20 w-full lg:w-1/2 mx-auto px-4 md:px-10">
        <div class="flex gap-3 items-center mb-6">
          <RouterLink
            to="/referral"
            class="flex-1 lg:flex-none flex justify-start"
          >
            <BaseIcon icon="arrow-left" color="#5F6C85" />
          </RouterLink>
          <h2
            class="text-mgm-txt-sm text-center lg:text-mgm-dspl-xs font-medium text-foreground-emphasis flex-1 lg:flex-none"
          >
            {{ $t("referral.invitations.title") }}
          </h2>
          <div class="flex-1 lg:flex-none"></div>
        </div>

        <div class="w-full lg:border lg:border-border-subtle lg:rounded-lg">
          <div class="grid grid-cols-2 border-b border-border-subtle">
            <button
              v-for="tab in tabs"
              :key="tab.value"
              @click="currentTab = tab.value as 'pending' | 'confirmed'"
              :class="`h-10 flex items-center justify-center border-b-2 text-mgm-txt-sm ${
                currentTab === tab.value
                  ? 'border-border-brand-emphasis text-foreground-emphasis'
                  : 'border-transparent text-foreground-default'
              }`"
            >
              {{ tab.label }}
            </button>
          </div>
          <div
            v-if="
              currentTab === 'pending' && data?.referrals.pending.length !== 0
            "
            class="p-4 max-h-[calc(100dvh_-_200px)] lg:max-h-[60dvh] overflow-y-auto"
          >
            <div
              v-for="user in data?.referrals.pending"
              :key="user.id"
              class="flex items-center gap-3 mb-3 last:mb-0"
            >
              <img :src="user.profilePictureUrl" class="w-8 h-8 rounded-full" />
              <div>
                <p class="text-mgm-txt-sm text-foreground-emphasis font-medium">
                  {{ user.firstname }} {{ user.lastname }}
                </p>
                <p class="text-mgm-txt-sm text-foreground-default">
                  {{ user.email }}
                </p>
              </div>
            </div>
          </div>
          <div
            v-if="
              currentTab === 'confirmed' &&
              data?.referrals.confirmed.length !== 0
            "
            class="p-4 max-h-[calc(100dvh_-_200px)] lg:max-h-[60dvh] overflow-y-auto"
          >
            <div
              v-for="user in data?.referrals.confirmed"
              :key="user.id"
              class="flex items-center gap-3 mb-3 last:mb-0"
            >
              <img :src="user.profilePictureUrl" class="w-8 h-8 rounded-full" />
              <div>
                <p class="text-mgm-txt-sm text-foreground-emphasis font-medium">
                  {{ user.firstname }} {{ user.lastname }}
                </p>
                <p class="text-mgm-txt-sm text-foreground-default">
                  {{ user.email }}
                </p>
              </div>
            </div>
          </div>
          <div
            v-if="
              (currentTab === 'pending' &&
                data?.referrals.pending.length === 0) ||
              (currentTab === 'confirmed' &&
                data?.referrals.confirmed.length === 0)
            "
            class="w-full h-[calc(100dvh_-_200px)] lg:h-[400px] flex flex-col items-center justify-center"
          >
            <div
              class="w-12 h-12 bg-[#E0EAFF] rounded-full border-8 border-[#EEF4FF] flex items-center justify-center mb-3"
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6 20.0872H8.61029C8.95063 20.0872 9.28888 20.1277 9.61881 20.2087L12.3769 20.8789C12.9753 21.0247 13.5988 21.0388 14.2035 20.9214L17.253 20.3281C18.0585 20.1712 18.7996 19.7854 19.3803 19.2205L21.5379 17.1217C22.154 16.5234 22.154 15.5524 21.5379 14.9531C20.9832 14.4134 20.1047 14.3527 19.4771 14.8103L16.9626 16.6449C16.6025 16.9081 16.1643 17.0498 15.7137 17.0498H13.2855L14.8311 17.0498C15.7022 17.0498 16.4079 16.3633 16.4079 15.5159V15.2091C16.4079 14.5055 15.9156 13.892 15.2141 13.7219L12.8286 13.1417C12.4404 13.0476 12.0428 13 11.6431 13C10.6783 13 8.93189 13.7988 8.93189 13.7988L6 15.0249M2 14.6L2 20.4C2 20.9601 2 21.2401 2.10899 21.454C2.20487 21.6422 2.35785 21.7951 2.54601 21.891C2.75992 22 3.03995 22 3.6 22H4.4C4.96005 22 5.24008 22 5.45399 21.891C5.64215 21.7951 5.79513 21.6422 5.89101 21.454C6 21.2401 6 20.9601 6 20.4V14.6C6 14.04 6 13.7599 5.89101 13.546C5.79513 13.3579 5.64215 13.2049 5.45399 13.109C5.24008 13 4.96005 13 4.4 13H3.6C3.03995 13 2.75992 13 2.54601 13.109C2.35785 13.2049 2.20487 13.3579 2.10899 13.546C2 13.7599 2 14.04 2 14.6ZM17.1914 3.59226C16.5946 2.3434 15.2186 1.68179 13.8804 2.32037C12.5423 2.95896 11.9722 4.47338 12.5325 5.80283C12.8787 6.62446 13.8707 8.22 14.5781 9.31903C14.8394 9.72511 14.9701 9.92815 15.161 10.0469C15.3247 10.1488 15.5297 10.2037 15.7224 10.1974C15.9471 10.1899 16.1618 10.0794 16.5911 9.85843C17.7532 9.26032 19.4101 8.37455 20.1208 7.83613C21.2707 6.96492 21.5556 5.36358 20.6947 4.14624C19.8337 2.92891 18.3327 2.80912 17.1914 3.59226Z"
                  stroke="#444CE7"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            <p
              class="text-mgm-dspl-xs text-foreground-emphasis font-medium mb-1"
            >
              {{ $t("referral.invitations.error.title") }}
            </p>
            <p class="text-center text-mgm-txt-sm text-foreground-default mb-5">
              {{
                $t("referral.invitations.error.text", {
                  organizationName: userStore.user?.organization?.name,
                })
              }}
            </p>
            <BaseButton
              size="lg"
              class="w-full sm:w-fit"
              @click="$router.push({ name: 'referral' })"
              >{{ $t("referral.invitations.error.cta") }}</BaseButton
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
