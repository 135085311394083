import { instances } from "@/services/api";
import type {
  SignUpHelpeePostReferredVerifyCodeRequestType,
  SignUpHelpeePostReferredVerifyCodeResponseType,
} from "@magma-app/magma-types/sign-up-helpee";
import { useMutation } from "@tanstack/vue-query";

export const useVerifyCode = () => {
  return useMutation({
    mutationFn: async (
      payload: SignUpHelpeePostReferredVerifyCodeRequestType
    ) => {
      const response = await instances.main.post(
        "/sign-up-helpee/referred/phone/verify-code",
        payload
      );
      return response.data as SignUpHelpeePostReferredVerifyCodeResponseType;
    },
  });
};
