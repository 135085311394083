import { instances } from "@/services/api";
import { useQuery } from "@tanstack/vue-query";
import type { ReviewsGetReviewIntegrationResponseType } from "@magma-app/magma-types/review";

export const useIntegration = (uuid?: string) => {
  return useQuery({
    queryKey: ["review-integration", uuid] as const,
    queryFn: async () => {
      const response = await instances.base.get(
        "/reviews/integrations/" + uuid
      );
      return response.data as ReviewsGetReviewIntegrationResponseType;
    },
    enabled: !!uuid,
    retry: 1,
    staleTime: Infinity,
  });
};
