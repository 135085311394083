<script setup lang="ts">
// NPM
import { onBeforeMount, onErrorCaptured, ref, watch } from "vue";
import { RouterView, useRoute, useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import type { AxiosError } from "axios";
import Cookies from "js-cookie";

// Components
import { BaseAlert, BaseLoader } from "@magma-app/magma-lapilli";
import CallTextModal from "@/components/modals/CallTextModal.vue";
import BaseSupport from "@/components/bases/BaseSupport.vue";
import ErrorView from "@/views/ErrorView.vue";
import ManageChallengesRewardsModal from "@/components/modals/ManageChallengesRewardsModal.vue";
import ManageRelationModal from "@/components/modals/ManageRelationModal.vue";
import ReportModal from "@/components/modals/ReportModal.vue";
import ShareModal from "@/components/modals/ShareModal.vue";
import SlideOverModal from "@/components/modals/SlideOverModal.vue";
import TheFooter from "@/components/shell/TheFooter.vue";
import TheNavbar from "@/components/shell/TheNavbar.vue";
import TouchpointsModal from "@/components/modals/TouchpointsModal.vue";
import QrCodeModal from "@/components/modals/QrCodeModal.vue";

// Helpers
import AnalyticsHelper from "@/helpers/analytics";
import UtilityHelper from "@/helpers/utility";

// Stores
import { useAuthStore } from "@/stores/auth";
import { useCommonStore } from "@/stores/common";
import { useRelationsStore } from "@/stores/relations";
import { useUserStore } from "@/stores/user";
const authStore = useAuthStore();
const commonStore = useCommonStore();
const relationsStore = useRelationsStore();
const userStore = useUserStore();

// Plugins
const route = useRoute();
const router = useRouter();
const { locale } = useI18n({ useScope: "global" });

// Reactive variables
const error = ref<Error | AxiosError | null>(null);
const errorCode = ref<number | null>(null);
const initialized = ref(false);

// Lifecycle hooks
onBeforeMount(async () => {
  // Initialize JWT token
  const token = (route?.params.token || route?.query.token) as string;

  if (token) {
    await authStore.getJwtToken(token);
  }

  // Redirect to sign in when pages are accessed without a token
  const publicPages = [
    "helpee-campaigns",
    "helpee-signup",
    "expired-link",
    "login",
    "referred",
    "referred-sharing",
    "token",
    "helpee-thanks",
    "helpee-qualification",
    "helper-onboarding",
    "helper-signup",
    "reviews",
    "helpee-complete-signup",
    "public-reviews",
    "share-review",
    "helpee-finish",
    "helpee-waiting",
    "referral-invite",
  ];
  const authRequired = !publicPages.includes(route?.name as string);
  let jwtToken = undefined;

  if (!route?.query.widget) {
    try {
      jwtToken = Cookies.get("jwtToken") || localStorage.getItem("jwtToken");
    } catch (e) {
      console.log("We don't have access to the local storage or cookies");
    }
  }

  if (authRequired && !jwtToken) {
    router.push({ name: "login" });
  }

  if (
    !["expired-link", "login", "public-reviews", "share-review"].includes(
      route?.name as string
    ) &&
    !(
      route?.name === "report-relation-view" &&
      route?.params.userType === "helpee"
    ) &&
    route?.meta.role != "helpee" &&
    jwtToken
  ) {
    await userStore.getUser(true, false, true, true);

    if (userStore.user?.language) {
      locale.value = userStore.user.language;
    } else {
      locale.value = navigator.language.split("-")[0];
    }

    if (authStore.jwtToken && route?.name === "token") {
      // Redirect to home
      router.push({ name: "relations-empty-state" });
    }

    if (route?.meta.role != "helpee") {
      await userStore.getCampaigns();
    }
  }

  // Redirect to homepage when rewards are disabled
  if (
    ["rewards", "purchased-rewards"].includes(route?.name as string) &&
    !userStore.user?.organization?.rewardEnabled
  ) {
    router.push({ name: "relations" });
  }

  if (route?.name === "referred" && route?.params.referredUuid) {
    await authStore.getReferredSignUpData(route?.params.referredUuid as string);
  }

  initialized.value = true;

  if (
    ![
      "expired-link",
      "helpee-campaigns",
      "helpee-signup",
      "helpee-qualification",
      "login",
      "referred",
      "referred-sharing",
      "helpee-thanks",
      "helpee-complete-signup",
      "share-review",
      "public-reviews",
      "helpee-finish",
      "helpee-waiting",
    ].includes(route?.name as string) &&
    jwtToken
  ) {
    if (route?.params.relationId) {
      await userStore.getSupportData(
        parseInt(route?.params.relationId as string)
      );
    } else {
      await userStore.getSupportData();
    }
  }

  // Load scripts
  UtilityHelper.loadScript(import.meta.env.VITE_FATHOM_ANALYTICS_URL, {
    "data-site": import.meta.env.VITE_FATHOM_ANALYTICS_KEY,
  });

  // Initialize analytics libraries
  AnalyticsHelper.initialize();
});

// Watchers
watch(route, async (newRoute) => {
  if (
    newRoute?.name === "helpee-qualification" &&
    authStore.helpeeQualificationData?.language
  ) {
    console.log("ok");
    locale.value = authStore.helpeeQualificationData.language;
  }
  if (newRoute?.name === "helper-signup" && userStore.campaign) {
    locale.value = userStore.campaignDetails.language;
  } else if (
    ["helpee-campaigns", "helpee-signup"].includes(newRoute?.name as string) &&
    authStore.helpeeSignUpData?.language
  ) {
    locale.value = authStore.helpeeSignUpData.language;
  } else if (newRoute?.name === "referred" && authStore.referredSignUpData) {
    locale.value = authStore.referredSignUpData.referred.language;
  } else if (
    newRoute?.name === "referred-sharing" &&
    authStore.referredSharingSignUpData?.organization.languages
  ) {
    locale.value =
      authStore.referredSharingSignUpData?.organization.languages[0];
  } else if (
    ["helpee-relation-overview", "report-relation-view"].includes(
      newRoute?.name as string
    ) &&
    relationsStore.relation
  ) {
    locale.value = relationsStore.relation.language;
  } else if (
    ["expired-link", "login"].includes(newRoute?.name as string) &&
    authStore.signInData
  ) {
    locale.value = authStore.signInData.language;
  } else if (userStore.user?.language) {
    locale.value = userStore.user.language;
  } else {
    locale.value = navigator.language.split("-")[0];
  }
});

// Listeners
onErrorCaptured((e: AxiosError) => {
  if (e.config && e.config.url === "/sign-up-helpee/phone/verify-code") {
    return false;
  }

  error.value = {
    message: e.message,
    name: e.name,
    stack: e.stack,
  };

  if (e.response) {
    errorCode.value = e.response.status;
  }

  return true;
});
</script>

<template>
  <div
    v-if="initialized"
    class="relative flex h-full min-h-dvh flex-col bg-background-default"
    :class="{
      'pb-36 sm:pb-24': ['helper-abcd'].includes($route?.name as string),
    }"
  >
    <TheNavbar
      v-if="
        $route?.name != 'helpee-relation-overview' &&
        $route?.name != 'error' &&
        $route?.name != 'expired-link' &&
        $route?.name != 'login' &&
        $route?.name != 'referred-sharing' &&
        $route?.name != 'report-relation-view' &&
        $route?.name != 'helpee-signup' &&
        $route?.name != 'helpee-thanks' &&
        $route?.name != 'start-relation-helpee' &&
        $route?.name != 'start-relation-helper' &&
        $route?.name != 'helpee-qualification' &&
        $route?.name != 'helper-onboarding' &&
        $route?.name != 'helper-signup' &&
        $route?.name != 'reviews' &&
        $route?.name != 'helpee-complete-signup' &&
        $route?.name != 'public-reviews' &&
        $route?.name != 'share-review' &&
        $route?.name != 'helpee-finish' &&
        $route?.name != 'referral-invite' &&
        $route?.name != 'helpee-waiting'
      "
      :class="{
        'lg:hidden':
          $route?.name === 'available-challenges' ||
          $route?.name === 'ongoing-challenges' ||
          $route?.name === 'validated-challenges' ||
          $route?.name === 'rejected-challenges' ||
          $route?.name === 'completed-challenges' ||
          $route?.name === 'helper-moment-overview' ||
          $route?.name === 'helper-relation-overview' ||
          $route?.name === 'helper-status' ||
          $route?.name === 'purchased-rewards' ||
          $route?.name === 'referral' ||
          $route?.name === 'referral-invitations' ||
          $route?.name === 'relations' ||
          $route?.name === 'rewards' ||
          $route?.name === 'sharing' ||
          $route?.name === 'relations-empty-state',
      }"
    />

    <ErrorView
      v-if="error && !['expired-link', 'login'].includes(route?.name as string)"
      :error-code="errorCode"
      :is-logged="!!authStore.jwtToken"
    />
    <RouterView
      v-else-if="
        !error || ['expired-link', 'login'].includes(route?.name as string)
      "
      class="flex-1"
    />

    <TheFooter
      v-if="['helper-abcd'].includes($route?.name as string)"
      class="absolute bottom-0 left-0 right-0"
    />
  </div>

  <BaseLoader v-else class="min-h-screen" />

  <BaseSupport
    v-if="
      initialized &&
      ![
        'expired-link',
        'helpee-campaigns',
        'helpee-signup',
        'login',
        'referred',
        'share-review',
        'public-reviews',
      ].includes(route?.name as string) &&
      userStore.supportData
    "
    v-bind="commonStore.adminModal.props"
  />

  <BaseAlert v-bind="commonStore.alert.props" />

  <CallTextModal v-bind="commonStore.callTextModal.props" />

  <ManageChallengesRewardsModal
    v-bind="commonStore.manageChallengesRewardsModal.props"
  />

  <ManageRelationModal v-bind="commonStore.manageRelationModal.props" />

  <TouchpointsModal v-bind="commonStore.touchpointsModal.props" />

  <ReportModal v-bind="commonStore.reportModal.props" />

  <ShareModal v-bind="commonStore.shareModal.props" />

  <SlideOverModal
    v-if="Object.keys(commonStore.slideOverModal.props)?.length > 0"
    v-bind="commonStore.slideOverModal.props"
  />

  <QrCodeModal v-bind="commonStore.qrCodeModal.props" />
</template>
