<script setup lang="ts">
import { useReferrals } from "@/api/referrals/use-referrals";
import NewReferral from "@/components/referral/new-referral.vue";
import TheSidebar from "@/components/shell/TheSidebar.vue";
import { BaseButton, BaseIcon } from "@magma-app/magma-lapilli";
import { computed, ref } from "vue";
import { RouterLink } from "vue-router";

const { data, isLoading } = useReferrals();

const copiedLink = ref(false);

const linkInviteHelper = computed(() => {
  return `${
    process.env.NODE_ENV === "production"
      ? `https://v3.magma.app/referral/invite?ambassadorRefId=${data.value?.ambassadorRefId}&referralCampaignUuid=${data.value?.referralCampaignUuid}`
      : `https://staging-v3.dashboard.magma.app/referral/invite?ambassadorRefId=${data.value?.ambassadorRefId}&referralCampaignUuid=${data.value?.referralCampaignUuid}`
  }`;
});

const handleCopyLink = () => {
  navigator.clipboard.writeText(linkInviteHelper.value).then(() => {
    copiedLink.value = true;
    setTimeout(() => {
      copiedLink.value = false;
    }, 1500);
  });
};
</script>

<template>
  <div class="flex bg-background-subtle">
    <TheSidebar />
    <div
      v-if="!isLoading"
      class="w-full flex-col bg-background-default lg:ml-20 lg:mt-3 lg:rounded-tl-[32px] lg:border lg:border-border-subtle lg:border-b-transparent 2xl:ml-64"
    >
      <div
        class="w-full bg-background-brand-emphasis pt-10 pb-32 lg:rounded-tl-[32px]"
      >
        <div class="lg:w-1/2 mx-auto px-6">
          <h1 class="text-mgm-dspl-xs text-foreground-on-emphasis font-medium">
            {{ $t("referral.base.title") }}
          </h1>
        </div>
      </div>
      <div class="lg:w-1/2 mx-auto px-6">
        <div
          class="bg-white -mt-24 p-4 border border-border-default rounded shadow-lg mb-10"
        >
          <p class="text-mgm-txt-sm font-medium text-foreground-emphasis mb-2">
            {{ $t("referral.base.label") }}
          </p>
          <div
            class="w-full h-10 rounded border border-border-subtle bg-background-subtle flex items-center justify-between overflow-hidden"
          >
            <div
              class="text-mgm-txt-sm text-foreground-default truncate flex-1 px-3"
            >
              {{ linkInviteHelper }}
            </div>
            <button
              class="h-10 px-4 bg-white text-mgm-txt-sm font-medium w-32"
              @click="handleCopyLink"
            >
              {{
                copiedLink
                  ? $t("referral.base.copy.copied")
                  : $t("referral.base.copy.cta")
              }}
            </button>
          </div>
          <div class="border-t border-border-subtle my-6 relative">
            <span
              class="text-mgm-txt-sm text-foreground-subtle uppercase absolute top-0 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white px-2"
              >or</span
            >
          </div>
          <div class="flex justify-center">
            <NewReferral v-if="data?.canReferSomeone">
              <template #trigger>
                <BaseButton size="lg" class="w-full">{{
                  $t("referral.base.cta")
                }}</BaseButton>
              </template>
            </NewReferral>
            <BaseButton v-else disabled size="lg" class="w-full">{{
              $t("referral.base.cta")
            }}</BaseButton>
          </div>
        </div>
        <div class="border border-border-subtle rounded">
          <div class="grid grid-cols-2">
            <div class="p-4">
              <div
                class="flex items-center gap-2 text-mgm-txt-sm text-foreground-default mb-2"
              >
                <BaseIcon icon="mail" color="#5F6C85" />
                <p>{{ $t("referral.base.pending") }}</p>
              </div>
              <p class="text-mgm-dspl-xs font-medium text-foreground-emphasis">
                {{ data?.referrals.pending.length }}
              </p>
            </div>
            <div class="p-4 border-l border-border-subtle">
              <div
                class="flex items-center gap-2 text-mgm-txt-sm text-foreground-default mb-2"
              >
                <BaseIcon icon="check-circle" color="#5F6C85" />
                <p>{{ $t("referral.base.confirmed") }}</p>
              </div>
              <p class="text-mgm-dspl-xs font-medium text-foreground-emphasis">
                {{ data?.referrals.confirmed.length }}
              </p>
            </div>
          </div>
          <RouterLink
            to="/referral/invitations"
            class="w-full border-t border-border-subtle h-14 flex items-center gap-2 px-4"
          >
            <p class="text-mgm-txt-sm text-foreground-brand-default">
              {{ $t("referral.base.see") }}
            </p>
            <BaseIcon icon="arrow-right" color="#444CE7" />
          </RouterLink>
        </div>
      </div>
    </div>
  </div>
</template>
