import { useQuery } from "@tanstack/vue-query";
import type {
  SignUpHelpeeGetReferredRequestType,
  SignUpHelpeeGetReferredResponseType,
} from "@magma-app/magma-types/sign-up-helpee";
import { instances } from "@/services/api";

export const useReferralInvite = (
  payload: SignUpHelpeeGetReferredRequestType
) => {
  return useQuery({
    queryKey: ["referrals-invite"] as const,
    queryFn: async () => {
      const query = {
        referredUuid: payload.referredUuid,
        ambassadorRefId: payload.ambassadorRefId,
        referralCampaignUuid: payload.referralCampaignUuid,
      };

      // transform query object to query string params
      const queryString = Object.keys(query)
        .map((key) => {
          if (query[key as keyof typeof query]) {
            return `${key}=${query[key as keyof typeof query]}`;
          }
          return "";
        })
        .join("&")
        .replace("&", "");

      console.log(queryString);

      const response = await instances.main.get(
        `/sign-up-helpee/referred?${queryString}`
      );
      return response.data as SignUpHelpeeGetReferredResponseType;
    },
    staleTime: Infinity,
    retry: 0,
  });
};
