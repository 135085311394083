import { instances } from "@/services/api";
import type { SignUpHelpeePostReferredSendCodeRequestType } from "@magma-app/magma-types/sign-up-helpee";
import { useMutation } from "@tanstack/vue-query";

export const useResendCode = () => {
  return useMutation({
    mutationFn: async (
      payload: SignUpHelpeePostReferredSendCodeRequestType
    ) => {
      const response = await instances.main.post(
        "/sign-up-helpee/referred/phone/send-code",
        payload
      );
      return response.data;
    },
  });
};
