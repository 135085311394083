import { instances } from "@/services/api";
import { useQuery } from "@tanstack/vue-query";
import type { ReviewsGetPublicReviewResponseType } from "@magma-app/magma-types/review";

export const useReview = (uuid?: string) => {
  return useQuery({
    queryKey: ["review-link", uuid] as const,
    queryFn: async () => {
      const response = await instances.base.get("/reviews/" + uuid);
      return response.data as ReviewsGetPublicReviewResponseType;
    },
    enabled: !!uuid,
    retry: 1,
    staleTime: Infinity,
  });
};
