<script setup lang="ts">
import Phone from "@/components/views/helpee-signup-simple/phone.vue";
import Code from "@/components/views/helpee-signup-simple/code.vue";
import Needs from "@/components/views/helpee-signup-simple/needs.vue";
import TouchPoints from "@/components/views/helpee-signup-simple/touchpoints.vue";
import Finish from "@/components/views/helpee-signup-simple/finish.vue";
import { onMounted, reactive } from "vue";
import { ref } from "vue";
import { useRoute } from "vue-router";
import { useHelpeeData } from "@/api/complete-helpee-signup/use-helpee-data";
import { useAuthStore } from "@/stores/auth";

const authStore = useAuthStore();
const route = useRoute();

const { data, isLoading } = useHelpeeData(
  route.params.campaignHelpeeUuid as string | undefined
);

const currentStep = ref<"phone" | "code" | "needs" | "touchpoints" | "finish">(
  "phone"
);
const infos = reactive({
  phone: "",
  accept: false,
  criterias: [] as { id: string; answerKey?: string; answerKeys?: string[] }[],
  expectation: "",
  touchPoints: [] as any[],
  acceptMail: true,
  acceptPhone: false,
});

const handlePhoneSubmit = async (d: { phone: string; accept: boolean }) => {
  infos.phone = d.phone;
  infos.accept = d.accept;

  await authStore.sendCodeRequest({
    phoneNumber: d.phone,
    helpeeId: data?.value?.signUpInformation?.helpeeId || 0,
  });

  currentStep.value = "code";
};

const handleResendCode = async () => {
  await authStore.sendCodeRequest({
    phoneNumber: infos.phone,
    helpeeId: data?.value?.signUpInformation?.helpeeId || 0,
  });
};

const handleCodeSubmit = async () => {
  currentStep.value = "needs";
};

const handleNeedsSubmit = async (d: {
  criterias: { id: string; answerKey?: string; answerKeys?: string[] }[];
  expectation: string;
}) => {
  infos.criterias = d.criterias;
  infos.expectation = d.expectation;
  await authStore.addOptionalInfoSignUpHelpee({
    campaignUuid: data?.value?.signUpInformation?.campaignUuid || "",
    helpeeId: data?.value?.signUpInformation?.helpeeId || 0,
    organizationId: data?.value?.signUpInformation?.organizationId || 0,
    expectation: d.expectation,
    userCriteria: d.criterias.map((c) => ({
      [`${c.answerKey ? "answerKey" : "answerKeys"}`]: c.answerKey
        ? c.answerKey
        : c.answerKeys,
      criteriaId: Number(c.id),
    })),
  });

  currentStep.value = "touchpoints";
};

const handleTouchPointsSubmit = (d: {
  touchPoints: any[];
  acceptMail: boolean;
  acceptPhone: boolean;
}) => {
  infos.touchPoints = d.touchPoints;
  infos.acceptMail = d.acceptMail;
  infos.acceptPhone = d.acceptPhone;

  authStore.sendTouchPointsSignUpHelpee({
    campaignHelpeeUuid: route.params.campaignHelpeeUuid as string,
    touchPoints: d.touchPoints,
    acceptEmailNotifications: d.acceptMail,
    acceptPhoneNotifications: d.acceptPhone,
  });

  currentStep.value = "finish";
};

onMounted(() => {
  const { finish } = route.query;

  if (finish === "true") {
    currentStep.value = "finish";
  }
});
</script>

<template>
  <main
    v-if="!isLoading"
    class="flex flex-col bg-background-subtle lg:justify-center lg:items-center"
  >
    <div
      class="bg-white rounded-lg lg:shadow-lg w-full lg:w-[480px] h-dvh lg:h-auto flex flex-col"
    >
      <div
        class="h-16 flex items-center gap-2 px-10 border-b border-border-subtle"
      >
        <img
          :src="data?.signUpInformation?.logoUrl"
          :alt="data?.signUpInformation?.name"
          class="rounded-full h-8 w-8ain"
        />
        <p class="text-mgm-txt-sm text-foreground-emphasis font-medium">
          {{ data?.signUpInformation?.name }}
        </p>
      </div>
      <Phone
        v-if="currentStep === 'phone'"
        :data="data"
        @submit="handlePhoneSubmit"
      />
      <Code
        v-if="currentStep === 'code'"
        @back="currentStep = 'phone'"
        @submit="handleCodeSubmit"
        @resend="handleResendCode"
      />
      <Needs v-if="currentStep === 'needs'" @submit="handleNeedsSubmit" />
      <TouchPoints
        v-if="currentStep === 'touchpoints'"
        :email="data?.signUpInformation?.email || ''"
        :phoneNumber="infos.phone"
        :disable-email="false"
        @submit="handleTouchPointsSubmit"
      />
      <Finish v-if="currentStep === 'finish'" />
    </div>
    <a
      class="mx-auto mt-8 text-center text-mgm-txt-xs font-normal text-foreground-default hover:text-foreground-default focus:text-foreground-default hidden lg:flex items-center"
      href="https://www.magma.app/"
      target="_blank"
    >
      {{ $t("generic.powered") }}
      <img class="ml-2" src="/img/logo_magma_full_bw.svg" />
    </a>
  </main>
</template>
