<script setup lang="ts">
import type { ITouchPoint } from "@/types/ITouchPoint";
import { FieldCheckbox, FieldInput } from "@magma-app/magma-lapilli";
import { useI18n } from "vue-i18n";

const tempTouchpoints = [
  {
    touchPointId: 2,
    name: "instagram",
    type: "pseudo",
    icon: "https://magma-assets.s3.eu-west-3.amazonaws.com/instagram.png",
    value: "",
  },
  {
    touchPointId: 1,
    name: "facebook",
    type: "pseudo",
    icon: "https://magma-assets.s3.eu-west-3.amazonaws.com/facebook.png",
    value: "",
  },
  {
    touchPointId: 3,
    name: "messenger",
    type: "pseudo",
    icon: "https://magma-assets.s3.eu-west-3.amazonaws.com/messenger.png",
    value: "",
  },
  {
    touchPointId: 14,
    name: "linkedin",
    type: "pseudo",
    icon: "https://magma-assets.s3.eu-west-3.amazonaws.com/linkedin.png",
    value: "",
  },
  {
    touchPointId: 7,
    name: "snapchat",
    type: "pseudo",
    icon: "https://magma-assets.s3.eu-west-3.amazonaws.com/snapchat.png",
    value: "",
  },
  {
    touchPointId: 4,
    name: "X (Twitter)",
    type: "pseudo",
    icon: "https://magma-assets.s3.eu-west-3.amazonaws.com/x.png",
    value: "",
  },
  {
    touchPointId: 5,
    name: "whatsapp",
    type: "phoneNumber",
    icon: "https://magma-assets.s3.eu-west-3.amazonaws.com/whatsapp.png",
    value: "",
  },
  {
    touchPointId: 8,
    name: "telegram",
    type: "pseudo",
    icon: "https://magma-assets.s3.eu-west-3.amazonaws.com/telegram.png",
    value: "",
  },
  {
    touchPointId: 6,
    name: "line",
    type: "phoneNumber",
    icon: "https://magma-assets.s3.eu-west-3.amazonaws.com/line.png",
    value: "",
  },
  {
    touchPointId: 15,
    name: "discord",
    type: "pseudo",
    icon: "https://magma-assets.s3.eu-west-3.amazonaws.com/discord.png",
    value: "",
  },
  {
    touchPointId: 16,
    name: "calendly",
    type: "pseudo",
    icon: "https://magma-assets.s3.eu-west-3.amazonaws.com/calendly-logo.png",
    value: "",
  },
  {
    touchPointId: 13,
    name: "weChat",
    type: "pseudo",
    icon: "https://magma-assets.s3.eu-west-3.amazonaws.com/wechat.png",
    value: "",
  },
  {
    touchPointId: 9,
    name: "tiktok",
    type: "pseudo",
    icon: "https://magma-assets.s3.eu-west-3.amazonaws.com/tiktok.png",
    value: "",
  },
  {
    touchPointId: 10,
    name: "youtube",
    type: "pseudo",
    icon: "https://magma-assets.s3.eu-west-3.amazonaws.com/youtube.png",
    value: "",
  },
  {
    touchPointId: 17,
    name: "calendar",
    type: "pseudo",
    icon: "https://magma-bucket.s3.eu-west-3.amazonaws.com/calendar.png",
    value: "",
  },
  {
    touchPointId: 18,
    name: "littleredbook",
    type: "pseudo",
    icon: "https://magma-bucket.s3.eu-west-3.amazonaws.com/littleredbook.png",
    value: "",
  },
];

const touchPointsPrefix = {
  instagram: "https://www.instagram.com/",
  facebook: "https://www.facebook.com/",
  "X (Twitter)": "https://www.twitter.com/",
  telegram: "https://t.me/",
  tiktok: "https://www.tiktok.com/@",
  youtube: "https://www.youtube.com/channel/",
  weChat: "https://weixin.qq.com/",
  linkedin: "https://www.linkedin.com/in/",
  discord: null,
  calendar: null,
  whatsapp: null,
  line: "https://line.me/",
  phone: null,
  messenger: "https://m.me/",
  snapchat: "https://www.snapchat.com/add/",
};

const touchPointsPlaceholder = {
  instagram: "username",
  facebook: "username",
  "X (Twitter)": "username",
  telegram: "username",
  tiktok: "username",
  youtube: "username",
  weChat: "username",
  linkedin: "username",
  discord: "username",
  calendar: "url",
  whatsapp: "phone",
  line: "username",
  phone: "phone",
  messenger: "username",
  snapchat: "username",
  littleredbook: "url",
};

const props = defineProps([
  "modelValue",
  "validation",
  "disableEmail",
  "email",
  "phoneNumber",
]);
const emit = defineEmits(["update:modelValue", "touchpointClick"]);

const { t } = useI18n();

const handleTouchPointClick = (touchpoint: ITouchPoint) => {
  emit("touchpointClick", touchpoint);
};
</script>

<template>
  <div>
    <div
      class="touchpoint-container flex-1 overflow-y-auto pb-0 scroll-pb-0 scrollbar-hide transform-[translate3d(0,0,0)]"
    >
      <h3 class="text-mgm-txt-lg font-medium text-foreground-emphasis mb-4">
        {{ t("helperOnboarding.touchPoints.title") }}
      </h3>

      <div class="pb-5 mb-5 border-b border-border-subtle">
        <FieldCheckbox
          id="email"
          :model-value="props.modelValue.acceptEmail"
          @change="
            emit('update:modelValue', {
              ...props.modelValue,
              acceptEmail: $event,
            })
          "
          data-cy="email"
          class="mb-3"
        >
          <span class="text-mgm-txt-sm text-foreground-default block">
            {{ $t("helpeeCampaignSignUpValidationView.touchPointMail") }}
            {{ props.email }}
          </span>
        </FieldCheckbox>
        <FieldCheckbox
          id="phone"
          :model-value="props.modelValue.acceptPhone"
          @change="
            emit('update:modelValue', {
              ...props.modelValue,
              acceptPhone: $event,
            })
          "
          data-cy="phone"
        >
          <span class="text-mgm-txt-sm text-foreground-default block">
            {{ $t("helpeeCampaignSignUpValidationView.touchPointPhone") }}
            {{ props.phoneNumber }}
          </span>
        </FieldCheckbox>

        <span
          class="flex items-center gap-2 text-mgm-txt-sm mt-3 text-foreground-danger-default"
          v-if="!props.modelValue.acceptEmail && !props.modelValue.acceptPhone"
        >
          <BaseIcon icon="alert-circle" color="#D92D20" />
          {{ $t("helpeeCampaignSignUpValidationView.touchPointError") }}
        </span>
      </div>

      <p class="mb-4 text-mgm-txt-sm text-foreground-default">
        {{ $t("helpeeCampaignSignUpValidationView.touchPointChoose") }}
      </p>

      <div class="flex flex-wrap gap-2 mb-4">
        <button
          v-for="touchpoint in tempTouchpoints"
          :class="`h-8 rounded-full py-1.5 px-3 bg-background-subtle inline-flex items-center text-mgm-txt-sm gap-2 border ${
            props.modelValue.selectedTouchpoints[touchpoint.name]
              ? 'border-border-brand-emphasis text-foreground-brand-default'
              : 'border-background-subtle text-foreground-default'
          }`"
          :key="touchpoint.touchPointId"
          @click="handleTouchPointClick(touchpoint)"
        >
          <img class="w-4 h-auto" :src="touchpoint.icon" />
          {{ touchpoint.name }}
        </button>
      </div>

      <FieldInput
        v-for="touchpoint of Object.values(
          props.modelValue.selectedTouchpoints
        ) as unknown as any"
        :id="`touchpoint-${touchpoint.touchPointId}`"
        :key="touchpoint.touchPointId"
        :model-value="
          props.modelValue.selectedTouchpoints[touchpoint.name].value
        "
        @input="
          emit('update:modelValue', {
            ...props.modelValue,
            selectedTouchpoints: {
              ...props.modelValue.selectedTouchpoints,
              [touchpoint.name]: {
                ...props.modelValue.selectedTouchpoints[touchpoint.name],
                value: $event.target.value,
              },
            },
          })
        "
        :validation="props.validation[touchpoint.name]"
        :data-cy="touchpoint.touchPointId + '-touchpoint'"
        class="mt-4 touchpoint-input"
        :role="touchpoint.type === 'email' ? 'email' : 'default'"
        :type="
          touchpoint.type === 'phoneNumber'
            ? 'tel'
            : touchpoint.type === 'email'
              ? 'email'
              : touchpoint.type === 'url'
                ? 'url'
                : 'text'
        "
        :locale="$i18n.locale"
        :placeholder="
          touchPointsPlaceholder[
            touchpoint.name as keyof typeof touchPointsPlaceholder
          ] === 'phone'
            ? '+33606060606'
            : touchPointsPlaceholder[
                  touchpoint.name as keyof typeof touchPointsPlaceholder
                ] === 'email'
              ? `${$t('generic.example')}@email.com`
              : touchPointsPlaceholder[
                    touchpoint.name as keyof typeof touchPointsPlaceholder
                  ] === 'url'
                ? `https://www.${$t('generic.example')}.com/${$t(
                    'generic.example'
                  )}`
                : `${$t('generic.username')}`
        "
        :custom-icon="touchpoint.icon"
        :prefix="
          touchPointsPrefix[touchpoint.name as keyof typeof touchPointsPrefix]
            ? (touchPointsPrefix[
                touchpoint.name as keyof typeof touchPointsPrefix
              ] as string)
            : undefined
        "
      />
    </div>
  </div>
</template>
