<script setup lang="ts">
import { useReview } from "@/api/reviews/use-review";
import ReviewCard from "@/components/reviews/review-card.vue";
import { BaseLoader } from "@magma-app/magma-lapilli";
import { useRouter } from "vue-router";

const router = useRouter();
const { uuid } = router.currentRoute.value.params;

const { data: review, isLoading } = useReview(uuid as string | undefined);
</script>

<template>
  <div
    v-if="!isLoading"
    class="w-screen h-screen flex flex-col bg-background-subtle"
  >
    <div
      class="flex px-5 md:px-10 py-6 justify-between bg-white md:bg-transparent border-b md:border-b-0 border-border-subtle"
    >
      <div class="flex items-center gap-3">
        <img
          :src="review?.organization.logoUrl || ''"
          :alt="review?.organization.name"
          class="w-8 h-8 rounded-full"
        />
        <p
          class="text-mgm-txt-sm font-medium text-foreground-emphasis whitespace-nowrap"
        >
          {{ review?.organization.name }}
        </p>
      </div>
      <a
        href="https://magma.app"
        rel="noreferrer noopener"
        class="flex items-center gap-3 fixed bottom-10 left-1/2 -translate-x-1/2 md:translate-x-0 md:static bg-white md:bg-transparent border md:border-0 border-border-subtle rounded-full px-3 py-1.5 md:py-0 w-max"
      >
        <p class="text-mgm-txt-sm text-foreground-subtle whitespace-nowrap">
          {{ $t("generic.powered") }}
        </p>
        <img src="/img/logo_magma_full_bw.svg" alt="Magma.app" />
      </a>
    </div>
    <div
      class="flex justify-center items-start mt-6 md:mt-0 md:items-center flex-1 px-5"
    >
      <ReviewCard :review="review as any" />
    </div>
  </div>
  <BaseLoader v-else />
</template>
