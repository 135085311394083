import { instances } from "@/services/api";
import type {
  UserDashboardGetReferralsResponseType,
  UserDashboardReferSomeonePostRequestType,
} from "@magma-app/magma-types/user-dashboard/referral";
import { useMutation, useQueryClient } from "@tanstack/vue-query";

export const useCreateRefer = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (payload: UserDashboardReferSomeonePostRequestType) => {
      const response = await instances.userDashboard.post("/referral", payload);
      return response.data;
    },
    onSuccess: (data) => {
      queryClient.setQueryData(
        ["referrals"],
        (oldData: UserDashboardGetReferralsResponseType) => {
          return {
            ...oldData,
            referrals: {
              ...oldData.referrals,
              pending: [...oldData.referrals.pending, data],
            },
          };
        }
      );
    },
  });
};
