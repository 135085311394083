// NPM
import { defineStore } from "pinia";
import Cookie from "js-cookie";

// Services
import Api from "@/services/api";

// Stores
import { useUserStore } from "@/stores/user";

// Types
import type { IPublicOrganizationData } from "@/types/IPublicOrganizationData";
import type { IPostSignUpData } from "@/types/IPostSignUpData";
import type { IReferredSharingSignUpData } from "@/types/IReferredSharingSignUpData";
import type { IReferredSignUpData } from "@/types/IReferredSignUpData";
import type { IUser } from "@/types/IUser";
import type {
  SignUpHelpeeGetQualificationResponseType,
  SignUpHelpeeUpdateExpectationRequestType,
  SignUpHelpeeUpdateTouchPointsRequestType,
} from "@magma-app/magma-types/sign-up-helpee";

export const useAuthStore = defineStore("auth", {
  state: () => {
    return {
      helpeePostCompleteSignUpData: null as IPostSignUpData | null,
      helpeePostMatchData: null as IPostSignUpData | null,
      helpeePostSignUpData: null as IPostSignUpData | null,
      helpeeSignUpData: null as IPublicOrganizationData | null,
      jwtToken: null as string | null,
      referredSharingRelationUrl: null as string | null,
      referredRelationUrl: null as string | null,
      referredSharingSignUpData: null as IReferredSharingSignUpData | null,
      referredSignUpData: null as IReferredSignUpData | null,
      signInData: null as IPublicOrganizationData | null,
      loginAs: null as { needLoginAs: string } | null,
      haveSendPhoneCode: false as boolean,
      codeValidated: null as {
        message: string;
        error?: boolean;
        errorType?: string;
      } | null,
      helpeeQualificationData:
        null as SignUpHelpeeGetQualificationResponseType | null,
      haveSendExpectation: false as boolean,
    };
  },

  actions: {
    async getJwtToken(token: string) {
      if (token) {
        const userStore = useUserStore();
        const response = await Api.userDashboard().auth.getJwtToken({
          token,
        });
        const jwtToken: string = response.data.jwtToken;
        const user: IUser = response.data.user;

        // Update stores
        this.jwtToken = jwtToken;
        userStore.$patch({ user });

        // Save JWT Token in local storage and cookies
        try {
          Cookie.set("jwtToken", jwtToken);
          localStorage.setItem("jwtToken", jwtToken);
        } catch (e) {
          console.log("We don't have access to the local storage or cookies");
        }
      }
    },

    async getSignInData(
      organizationSubdomainName: string,
      campaignUuid: string
    ) {
      const response = await Api.userDashboard().auth.getSignInData(
        organizationSubdomainName,
        campaignUuid
      );
      this.signInData = response?.data;
    },

    async signIn(email: string) {
      await Api.userDashboard().auth.signIn(email);
    },

    async signInAs(email: string, loginAs?: string) {
      const response = await Api.main().auth.signInAs(email, loginAs);

      this.loginAs = response.data;
    },

    async getHelpeeSignUpData(payload: {
      campaignUuid?: string;
      organizationUuid?: string;
    }) {
      const response =
        await Api.userDashboard().auth.getHelpeeSignUpData(payload);

      this.helpeeSignUpData = response.data;
    },

    async getReferredSignUpData(referredUuid: string) {
      const response =
        await Api.main().referral.getReferredSignUpData(referredUuid);

      this.referredSignUpData = response.data;
    },

    async getReferredSharingSignUpData(shareId: string) {
      const response =
        await Api.main().referral.getReferredSharingSignUpData(shareId);

      this.referredSharingSignUpData = response.data;
    },

    async sendReferredSignUpData(payload: {
      referredUuid: string;
      firstname: string;
      lastname: string;
      email: string;
      phoneNumber: string;
      strongUserCriteria: {
        criteriaId: string;
        answerKey?: string;
        answerKeys?: string[];
      };
    }) {
      const response =
        await Api.main().referral.sendReferredSignUpData(payload);

      this.referredRelationUrl = response.data.url;
    },

    async sendReferredSharingSignUpData(payload: {
      campaignUuid: string;
      helperId: number;
      shareId: string;
      firstname: string;
      lastname: string;
      email: string;
      phoneNumber: string;
      strongUserCriteria: {
        criteriaId: number;
        answerKey?: string;
        answerKeys?: string[];
      };
      helpeeTouchPoints: {
        touchPointId: string;
        value: string;
      }[];
    }) {
      const response =
        await Api.main().referral.sendReferredSharingSignUpData(payload);

      this.referredSharingRelationUrl = response.data.url;
    },

    async signUpHelpee(payload: {
      campaignUuid?: string;
      email: string;
      organizationId?: number;
      source?: string;
    }) {
      const response = await Api.main().auth.signUpHelpee(payload);

      this.helpeePostSignUpData = response.data;
    },

    async completeSignUpHelpee(payload: {
      campaignUuid: string;
      firstname: string;
      helpeeId: number | undefined;
      lastname: string;
      organizationId: number | undefined;
      phoneNumber: string;
      strongUserCriteria: {
        criteriaId: number;
        answerKey?: string;
        answerKeys?: string[];
      };
    }) {
      const response = await Api.main().auth.completeSignUpHelpee(payload);

      this.helpeePostMatchData = response.data;

      return response.data;
    },

    async sendCodeRequest(payload: { phoneNumber: string; helpeeId: number }) {
      await Api.main().auth.sendCodeRequest(payload);

      this.haveSendPhoneCode = true;
    },

    async validateCode(payload: { helpeeId: number; code: string }) {
      const response = await Api.main().auth.validateCode(payload);

      this.codeValidated = response.data;

      return response.data;
    },

    async addOptionalInfoSignUpHelpee(payload: {
      campaignUuid: string;
      helpeeId: number | undefined;
      organizationId: number | undefined;
      expectation?: string;
      userCriteria?: {
        criteriaId: number;
        answerKey?: string;
        answerKeys?: string[];
      }[];
    }) {
      const response =
        await Api.main().auth.addOptionalInfoSignUpHelpee(payload);

      this.helpeePostCompleteSignUpData = response.data;
    },

    async getCampaignHelpeeSignUpData(campaignHelpeeUuid: string) {
      const response =
        await Api.main().auth.getCampaignHelpeeSignUpData(campaignHelpeeUuid);

      this.helpeeQualificationData = response.data;
    },

    async sendExpectationSignUpHelpee(
      payload: SignUpHelpeeUpdateExpectationRequestType
    ) {
      await Api.main().auth.sendExpectationSignUpHelpee(payload);

      this.haveSendExpectation = true;
    },

    async sendTouchPointsSignUpHelpee(payload: {
      campaignHelpeeUuid: string;
      touchPoints: { touchPointId: number; value: string }[];
      acceptEmailNotifications: boolean;
      acceptPhoneNotifications: boolean;
    }) {
      await Api.main().auth.sendTouchPointsSignUpHelpee(payload);
    },
  },
  persist: new URL(window.location.href).search.includes("?widget=true")
    ? false
    : true,
});
