import { instances } from "@/services/api";
import type {
  SignUpHelpeePostReferredRequestType,
  SignUpHelpeePostReferredResponseType,
} from "@magma-app/magma-types/sign-up-helpee";
import { useMutation } from "@tanstack/vue-query";

export const useCreateHelpee = () => {
  return useMutation({
    mutationFn: async (payload: SignUpHelpeePostReferredRequestType) => {
      const response = await instances.main.post(
        "/sign-up-helpee/referred",
        payload
      );
      return response.data as SignUpHelpeePostReferredResponseType;
    },
  });
};
