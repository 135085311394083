<script setup lang="ts">
import { useAuthStore } from "@/stores/auth";
import { computed } from "vue";

const authStore = useAuthStore();

const helpersProfilePicture = computed(() => {
  const enoughHelpersPics =
    authStore.helpeeSignUpData?.helpersProfilePicture &&
    authStore.helpeeSignUpData?.helpersProfilePicture?.length > 4
      ? true
      : false;

  return [
    {
      picture: enoughHelpersPics
        ? authStore.helpeeSignUpData?.helpersProfilePicture?.[2]
        : "/img/team/hugo.png",
      position: "top-11 left-[15%]",
      size: "h-12 w-12",
    },
    {
      picture: enoughHelpersPics
        ? authStore.helpeeSignUpData?.helpersProfilePicture?.[3]
        : "/img/team/joris.png",
      position: "bottom-4 left-[20%]",
      size: "h-12 w-12",
    },
    {
      picture: enoughHelpersPics
        ? authStore.helpeeSignUpData?.helpersProfilePicture?.[0]
        : "/img/team/marine.png",
      position: "bottom-1/4 right-[40%]",
      size: "h-12 w-12",
    },
    {
      picture: enoughHelpersPics
        ? authStore.helpeeSignUpData?.helpersProfilePicture?.[4]
        : "/img/team/nada.png",
      position: "top-6 right-[30%]",
      size: "h-12 w-12",
    },
    {
      picture: enoughHelpersPics
        ? authStore.helpeeSignUpData?.helpersProfilePicture?.[5]
        : "/img/team/edouard.png",
      position: "bottom-4 right-[15%]",
      size: "h-12 w-12",
    },
    {
      blur: true,
      picture: "/img/avatars/blur_1.png",
      position: "top-1/3 right-[55%]",
      size: "h-8 w-8",
    },
    {
      blur: true,
      picture: "/img/avatars/blur_2.png",
      position: "top-[40%] right-7",
      size: "h-8 w-8",
    },
    {
      blur: true,
      picture: "/img/avatars/blur_3.png",
      position: "bottom-1/4 left-7",
      size: "h-8 w-8",
    },
  ];
});
</script>

<template>
  <div
    class="w-screen h-screen bg-background-subtle flex items-center flex-center"
  >
    <div
      data-cy="signup-confirmation"
      class="relative h-full text-center w-[90dvw] lg:w-[800px] bg-white mx-auto rounded-lg shadow-lg pb-20"
    >
      <div
        :class="[
          `relative h-[200px] bg-[url('/img/illustration_redirection_background.svg')] bg-cover bg-center md:rounded-t-[15px]`,
        ]"
      >
        <div
          v-for="(helper, helperIndex) in helpersProfilePicture"
          :key="helperIndex"
          :class="['absolute', helper.position]"
        >
          <div
            :class="[
              'overflow-hidden rounded-full',
              helper.size,
              { 'border-2 border-border-on-emphasis': !helper.blur },
            ]"
          >
            <img class="h-full w-full object-cover" :src="helper.picture" />
          </div>
        </div>
      </div>

      <h3
        class="mx-auto mt-20 max-w-xs text-mgm-txt-xl font-semibold text-foreground-emphasis"
      >
        {{ $t("helpeeCampaignSignUpView.redirection.title") }}
      </h3>

      <p
        class="mx-auto mt-4 max-w-xs text-mgm-txt-sm font-normal text-foreground-default"
      >
        {{ $t("helpeeCampaignSignUpView.redirection.description") }}
      </p>
    </div>
  </div>
</template>
