// NPM
import { createApp } from "vue";
import { createPinia } from "pinia";
import { Vue3Mq } from "vue3-mq";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
import { VueQueryPlugin } from "@tanstack/vue-query";

// Plugins
import i18n from "@/plugins/i18n";
import FloatingVue from "floating-vue";
import * as Sentry from "@sentry/vue";

// Vue
import App from "@/App.vue";
import router from "@/router";

// Stylesheets
// Order matters (overrides lapilli styles with codebase styles)
import "@magma-app/magma-lapilli/dist/style.css";
import "@/assets/base.css";
import "@/assets/floating-vue.css";
import MasonryWall from "@yeger/vue-masonry-wall";

// Initialize Vue App
const app = createApp(App);

// initialize pinia
const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

// Sentry Replay
Sentry.init({
  app,
  dsn: "https://7b4e24eda0007e8a95d8a7c3b7ffd2c6@o4507453002350592.ingest.de.sentry.io/4507453007462480",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      maskAllInputs: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

// Set up plugins
app.use(pinia);
app.use(i18n);
app.use(Vue3Mq, {
  preset: "tailwind",
  breakpoints: {
    xs: 0,
    sm: 640,
    md: 768,
    lg: 1024,
    xl: 1280,
    "2xl": 1440,
    xxl: 1536,
  },
});
app.use(FloatingVue);
app.use(router);
app.use(VueQueryPlugin);
app.use(MasonryWall);

// Attach Vue instance to the DOM
router.isReady().then(() => {
  app.mount("#app");
});
