<script setup lang="ts">
import { useCreateRefer } from "@/api/referrals/use-create-refer";
import { useReferrals } from "@/api/referrals/use-referrals";
import {
  BaseButton,
  BaseLoader,
  FieldCheckbox,
  FieldInput,
  Modal,
} from "@magma-app/magma-lapilli";
import { computed, ref } from "vue";

const { data: referrals } = useReferrals();
const { mutateAsync, status } = useCreateRefer();

const currentStep = ref<"info" | "success" | "error">("info");
const modalOpen = ref(false);
const data = ref({
  firstname: "",
  lastname: "",
  email: "",
  accept: false,
});

const validMail = computed(() => {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return emailRegex.test(data.value.email);
});

const handleSendInfo = async () => {
  const findInPending = referrals.value?.referrals.pending.find(
    (referral) => referral.email === data.value.email
  );

  const findInConfirmed = referrals.value?.referrals.confirmed.find(
    (referral) => referral.email === data.value.email
  );

  if (findInPending || findInConfirmed) {
    currentStep.value = "error";
    return;
  }

  await mutateAsync({
    email: data.value.email,
    firstname: data.value.firstname,
    lastname: data.value.lastname,
    referralCampaignUuid: referrals.value?.referralCampaignUuid,
  });

  currentStep.value = "success";
};

const handleClose = () => {
  modalOpen.value = false;
  currentStep.value = "info";
  data.value = {
    firstname: "",
    lastname: "",
    email: "",
    accept: false,
  };
};
</script>

<template>
  <Modal :open="modalOpen" @open-change="modalOpen = $event">
    <template #trigger>
      <slot name="trigger" />
    </template>
    <template #content>
      <div v-if="currentStep === 'info' && status !== 'pending'">
        <div
          class="w-12 h-12 bg-[#F1F5F9] rounded-full border-8 border-[#F8FAFC] flex items-center justify-center mb-5"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12 20H21M16.5 3.50001C16.8978 3.10219 17.4374 2.87869 18 2.87869C18.2786 2.87869 18.5544 2.93356 18.8118 3.04017C19.0692 3.14677 19.303 3.30303 19.5 3.50001C19.697 3.697 19.8532 3.93085 19.9598 4.18822C20.0665 4.44559 20.1213 4.72144 20.1213 5.00001C20.1213 5.27859 20.0665 5.55444 19.9598 5.81181C19.8532 6.06918 19.697 6.30303 19.5 6.50001L7 19L3 20L4 16L16.5 3.50001Z"
              stroke="#444CE7"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>

        <p class="text-mgm-txt-lg text-foreground-emphasis font-semibold mb-4">
          {{ $t("referral.new.title") }}
        </p>

        <FieldInput
          v-model="data.firstname"
          :label="$t('referral.new.firstname')"
          placeholder="Camille"
          class="mb-3"
          required
        />
        <FieldInput
          v-model="data.lastname"
          :label="$t('referral.new.lastname')"
          placeholder="Durand"
          class="mb-3"
          required
        />
        <FieldInput
          v-model="data.email"
          type="email"
          :label="$t('referral.new.email')"
          placeholder="camille@example.com"
          class="mb-3"
          required
        />
        <FieldCheckbox id="accept" v-model="data.accept" label>
          <span class="text-mgm-txt-sm">{{ $t("referral.new.accept") }}</span>
        </FieldCheckbox>
        <BaseButton
          size="lg"
          class="w-full mt-4"
          :disabled="
            data.firstname === '' ||
            data.lastname === '' ||
            !data.accept ||
            !validMail
          "
          @click="handleSendInfo"
          >{{ $t("referral.new.send") }}</BaseButton
        >
      </div>
      <div v-if="currentStep === 'success' && status !== 'pending'">
        <div
          class="w-12 h-12 bg-[#D1FADF] rounded-full border-8 border-[#ECFDF3] flex items-center justify-center mb-5"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M20 6L9 17L4 12"
              stroke="#03874C"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>

        <p class="text-mgm-txt-lg text-foreground-emphasis font-semibold mb-4">
          {{ $t("referral.new.sent.title") }}
        </p>

        <p class="text-mgm-txt-sm text-foreground-default">
          {{
            $t("referral.new.sent.text", {
              firstname: data.firstname,
              email: data.email,
            })
          }}
        </p>

        <BaseButton size="lg" class="w-full mt-5" @click="handleClose">{{
          $t("referral.new.sent.back")
        }}</BaseButton>
      </div>
      <div v-if="currentStep === 'error' && status !== 'pending'">
        <div
          class="w-12 h-12 bg-[#FEE4E2] rounded-full border-8 border-[#FEF3F2] flex items-center justify-center mb-5"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.9998 8.99996V13M11.9998 17H12.0098M10.2898 3.85996L1.81978 18C1.64514 18.3024 1.55274 18.6453 1.55177 18.9945C1.55079 19.3437 1.64127 19.6871 1.8142 19.9905C1.98714 20.2939 2.2365 20.5467 2.53748 20.7238C2.83847 20.9009 3.18058 20.9961 3.52978 21H20.4698C20.819 20.9961 21.1611 20.9009 21.4621 20.7238C21.763 20.5467 22.0124 20.2939 22.1853 19.9905C22.3583 19.6871 22.4488 19.3437 22.4478 18.9945C22.4468 18.6453 22.3544 18.3024 22.1798 18L13.7098 3.85996C13.5315 3.56607 13.2805 3.32308 12.981 3.15444C12.6814 2.98581 12.3435 2.89722 11.9998 2.89722C11.656 2.89722 11.3181 2.98581 11.0186 3.15444C10.7191 3.32308 10.468 3.56607 10.2898 3.85996Z"
              stroke="#D92D20"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>

        <p class="text-mgm-txt-lg text-foreground-emphasis font-semibold mb-4">
          {{ $t("referral.new.error.title") }}
        </p>

        <p class="text-mgm-txt-sm text-foreground-default">
          {{ $t("referral.new.error.text") }}
        </p>

        <BaseButton size="lg" class="w-full mt-5" @click="handleClose">{{
          $t("referral.new.error.back")
        }}</BaseButton>
      </div>
      <div
        v-if="status === 'pending'"
        class="w-full h-[350px] flex justify-center items-center"
      >
        <BaseLoader />
      </div>
    </template>
  </Modal>
</template>
