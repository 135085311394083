<script setup lang="ts">
import { ref } from "vue";
import Header from "@/components/views/helper-onboarding/header.vue";
import HeaderStart from "@/components/views/helper-onboarding/header-start.vue";
import Welcome from "@/components/views/helper-onboarding/steps/welcome.vue";
import Start from "@/components/views/helper-onboarding/steps/start.vue";
import Share from "@/components/views/helper-onboarding/steps/share.vue";
import Email from "@/components/views/helper-onboarding/steps/email.vue";
import Confirm from "@/components/views/helper-onboarding/steps/confirm.vue";
import AlreadyExist from "@/components/views/helper-onboarding/steps/already-exist.vue";
import Phone from "@/components/views/helper-onboarding/steps/phone.vue";
import Verification from "@/components/views/helper-onboarding/steps/verification.vue";
import Name from "@/components/views/helper-onboarding/steps/name.vue";
import Profile from "@/components/views/helper-onboarding/steps/profile.vue";
import Criteria from "@/components/views/helper-onboarding/steps/criteria.vue";
import Touchpoints from "@/components/views/helper-onboarding/steps/touchpoints.vue";
import Resume from "@/components/views/helper-onboarding/steps/resume.vue";
import { BaseButton } from "@magma-app/magma-lapilli";
import {
  createI18nMessage,
  email,
  maxLength,
  minLength,
  numeric,
  required,
} from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import { watch } from "vue";
import { useI18n } from "vue-i18n";
import { isValidPhoneNumber } from "libphonenumber-js";
import { useAuthStore } from "@/stores/auth";
import { watchEffect } from "vue";
import type { ITouchPoint } from "@/types/ITouchPoint";
import { useHelperSignupStore } from "@/stores/helper-signup";
import { useRoute, useRouter } from "vue-router";
import { useUserStore } from "@/stores/user";
import { computed } from "vue";
import { extractUtmOrCleanUrl } from "@/helpers/url";

type Steps =
  | "welcome"
  | "start"
  | "share"
  | "email"
  | "confirm"
  | "alreadyExist"
  | "phone"
  | "verification"
  | "name"
  | "profile"
  | "criteria"
  | "touchpoints"
  | "resume";

type FormData = {
  email: string;
  phoneNumber: string;
  code: string;
  firstName: string;
  lastName: string;
  profilePictureFile: File | null;
  profilePictureMediumUrl: string | null;
  profilePicture: string | null;
  bio: string;
  acceptEmail: boolean;
  acceptPhone: boolean;
  criteria: { [key: string]: string | string[] }[];
  selectedTouchpoints: { [key: string]: ITouchPoint };
};

const { t } = useI18n({ useScope: "global" });
const withI18nMessage = createI18nMessage({ t });

const authStore = useAuthStore();
const helperSignUpStore = useHelperSignupStore();
const userStore = useUserStore();

const route = useRoute();
const router = useRouter();

const currentStep = ref<Steps>("welcome");
const formData = ref<FormData>({
  email: "",
  phoneNumber: "",
  code: "",
  firstName: "",
  lastName: "",
  profilePictureFile: null,
  profilePictureMediumUrl: null,
  profilePicture: null,
  bio: "",
  acceptEmail: true,
  acceptPhone: true,
  criteria: [],
  selectedTouchpoints: {},
});
const disableEmail = ref<boolean>(false);
const disableNext = ref<boolean>(false);
const codeValidationResult = ref<{}>({});

const init = ref(false);
const isPublicRegister = ref(true);

const touchPointsPrefix = {
  instagram: "https://www.instagram.com/",
  facebook: "https://www.facebook.com/",
  "X (Twitter)": "https://www.twitter.com/",
  telegram: "https://t.me/",
  tiktok: "https://www.tiktok.com/@",
  youtube: "https://www.youtube.com/channel/",
  weChat: "https://weixin.qq.com/",
  linkedin: "https://www.linkedin.com/in/",
  discord: "https://discord.com/",
  calendar: null,
  whatsapp: null,
  line: "https://line.me/",
  phone: null,
  messenger: "https://m.me/",
  snapchat: "https://www.snapchat.com/add/",
};

const touchPointsPlaceholder = {
  instagram: "username",
  facebook: "username",
  "X (Twitter)": "username",
  telegram: "username",
  tiktok: "username",
  youtube: "username",
  weChat: "username",
  linkedin: "username",
  discord: "username",
  calendar: "url",
  whatsapp: "phone",
  line: "username",
  phone: "phone",
  messenger: "username",
  snapchat: "username",
  littleredbook: "url",
};

const formRules = {
  phone: "phoneNumber",
  line: "phoneNumber",
  whatsapp: "phoneNumber",
  calendly: "pseudo",
  discord: "pseudo",
  linkedin: "pseudo",
  weChat: "pseudo",
  youtube: "pseudo",
  tiktok: "pseudo",
  telegram: "pseudo",
  snapchat: "pseudo",
  "X (Twitter)": "pseudo",
  messenger: "pseudo",
  instagram: "pseudo",
  facebook: "pseudo",
} as { [key: string]: string };

const touchPointRules = Object.keys(formRules).reduce((acc: any, key) => {
  if (formRules[key] === "phoneNumber") {
    acc[key] = {
      validPhoneNumber: withI18nMessage(
        (value: any) => {
          if (value.value !== "") {
            return isValidPhoneNumber(value.value);
          } else {
            return true;
          }
        },
        {
          messagePath: () => t("generic.phoneNumber") + t("validation.valid"),
        }
      ),
    };
  } else if (formRules[key] === "pseudo") {
    acc[key] = {
      required: withI18nMessage(
        (value: any) => {
          return value.value !== "";
        },
        { messagePath: () => t("validation.required") }
      ),
    };
  }

  return acc;
}, {});

const validationRules = {
  email: {
    required: withI18nMessage(required, {
      messagePath: () => t("helperOnboarding.email.error"),
    }),
    email: withI18nMessage(email, {
      messagePath: () => t("validation.invalidEmail"),
    }),
  },
  phoneNumber: {
    required: withI18nMessage(required, {
      messagePath: () => t("validation.value"),
    }),
    validPhoneNumber: withI18nMessage(
      (value: string) => isValidPhoneNumber(value),
      {
        messagePath: () => t("generic.phoneNumber") + t("validation.valid"),
      }
    ),
  },
  code: {
    required: withI18nMessage(required, {
      messagePath: () => t("helperOnboarding.email.error"),
    }),
    minLength: withI18nMessage(minLength(6), {
      messagePath: () => t("validation.codeLength"),
    }),
    maxLength: maxLength(6),
    numeric,
  },
  firstName: {
    required: withI18nMessage(required, {
      messagePath: () => t("helperOnboarding.email.error"),
    }),
  },
  lastName: {
    required: withI18nMessage(required, {
      messagePath: () => t("helperOnboarding.email.error"),
    }),
  },
  profilePictureFile: {
    size: withI18nMessage(
      (value: File | null) => {
        if (!value) return true;

        if (value instanceof File && typeof value.size === "number") {
          return value.size < 9 * 1000 * 1000;
        }
        return false;
      },
      {
        messagePath: () => t("validation.pictureSize"),
      }
    ),
  },
  bio: {
    maxLength: withI18nMessage(maxLength(50), {
      messagePath: () => t("validation.maxLength50"),
    }),
  },
  selectedTouchpoints: {
    mail: {
      email: withI18nMessage(email, {
        messagePath: () => t("validation.invalidEmail"),
      }),
    },
    ...touchPointRules,
  },
};

const v$ = useVuelidate(validationRules, formData, {
  $externalResults: codeValidationResult,
});

const handleGoStepBack = () => {
  switch (currentStep.value) {
    case "email":
      currentStep.value = "welcome";
      break;
    case "phone":
      currentStep.value = "email";
      break;
    case "verification":
      currentStep.value = "phone";
      break;
    case "name":
      currentStep.value = "phone";
      break;
    case "profile":
      currentStep.value = "name";
      break;
    case "criteria":
      currentStep.value = "profile";
      break;
    case "touchpoints":
      currentStep.value = "criteria";
      break;
    case "resume":
      currentStep.value = "touchpoints";
      break;
  }
};

const handleGoStepForward = async () => {
  switch (currentStep.value) {
    case "welcome":
      currentStep.value = "start";
      break;
    case "start":
      currentStep.value = "share";
      break;
    case "share":
      currentStep.value = "email";
      break;
    case "email":
      disableNext.value = true;
      if (!disableEmail.value) {
        const source =
          (route?.query.source as string) ||
          (route?.query.utm_source as string) ||
          (route?.query.utm_medium as string) ||
          "";

        const createUser = await helperSignUpStore.createHelperByEmail({
          email: formData.value.email,
          campaignUuid: route.params.campaignUuid as string,
          source: extractUtmOrCleanUrl(source),
        });

        if (createUser.redirectTo === "confirm-email") {
          currentStep.value = "confirm";
        } else {
          currentStep.value = "alreadyExist";
        }
      } else {
        currentStep.value = "phone";
        v$.value.phoneNumber.$reset();
      }
      disableNext.value = false;
      break;
    case "phone":
      disableNext.value = true;
      if (
        helperSignUpStore.campaignData?.isPhoneVerified &&
        !v$.value.phoneNumber.$dirty
      ) {
        currentStep.value = "name";
      } else {
        await helperSignUpStore.registerPhoneNumber({
          phoneNumber: formData.value.phoneNumber,
        });

        currentStep.value = "verification";
        v$.value.code.$reset();
      }
      disableNext.value = false;
      break;
    case "verification":
      disableNext.value = true;
      try {
        await helperSignUpStore.verifyCode({
          code: formData.value.code,
        });
        v$.value.firstName.$reset();
        v$.value.lastName.$reset();
        currentStep.value = "name";
      } catch (e) {
        codeValidationResult.value = {
          code: t("helpeeCampaignSignUpView.codeWrong"),
        };
      }
      disableNext.value = false;
      break;
    case "name":
      disableNext.value = true;
      const up = await helperSignUpStore.updateInfo({
        firstname: formData.value.firstName,
        lastname: formData.value.lastName,
      });

      if (up) {
        formData.value.profilePictureMediumUrl = up.profilePictureUrlMedium;
      }

      v$.value.bio.$reset();
      v$.value.profilePictureFile.$reset();
      currentStep.value = "profile";
      disableNext.value = false;
      break;
    case "profile":
      disableNext.value = true;
      await helperSignUpStore.updateInfo({
        position: formData.value.bio,
      });

      if (formData.value.profilePictureFile) {
        const upload = await helperSignUpStore.updateProfilePicture({
          profilePicture: formData.value.profilePictureFile as any,
        });

        if (upload) {
          formData.value.profilePictureMediumUrl =
            upload.profilePictureUrlMedium;
          formData.value.profilePicture = upload.profilePictureUrl;
        }
      }
      currentStep.value = "criteria";
      disableNext.value = false;
      break;
    case "criteria":
      disableNext.value = true;
      const formattedCriterias = formData.value.criteria.map((c) => {
        return {
          criteriaId: c.criteriaId,
          [c.type === "singleSelect" ? "answerKey" : "answerKeys"]:
            c.type === "singleSelect" ? c.answerKey : c.answerKeys,
        };
      });
      await helperSignUpStore.sendCriteria({
        campaignUuid: route.params.campaignUuid as string,
        userCriteria: formattedCriterias as any,
      });
      currentStep.value = "touchpoints";
      disableNext.value = false;
      break;
    case "touchpoints":
      await helperSignUpStore.sendTouchPoints({
        campaignUuid: route.params.campaignUuid as string,
        acceptEmailNotifications: formData.value.acceptEmail,
        acceptPhoneNotifications: formData.value.acceptPhone,
        userTouchPoints: selectedTouchpointsToArray().map((touchpoint) => ({
          touchPointId: touchpoint.touchPointId,
          value: `${
            touchPointsPrefix[touchpoint.name as keyof typeof touchPointsPrefix]
          }${formData.value.selectedTouchpoints[touchpoint.name].value}`,
        })),
      });
      currentStep.value = "resume";
      disableNext.value = false;
      break;
    case "resume":
      disableNext.value = true;
      await userStore.getUser(true, true, true, true);
      router.push({
        name: "relations",
      });
      disableNext.value = false;
      break;
  }
};

const defineDisableNextStep = computed(() => {
  switch (currentStep.value) {
    case "email":
      console.log(formData.value.email == "");
      return v$.value.email.$error || formData.value.email == "";
    case "phone":
      return v$.value.phoneNumber.$error || formData.value.phoneNumber === "";
    case "verification":
      return v$.value.code.$error || formData.value.code === "";
    case "name":
      return (
        v$.value.firstName.$error ||
        formData.value.firstName === "" ||
        v$.value.lastName.$error ||
        formData.value.lastName === ""
      );
    case "profile":
      return v$.value.bio.$error || v$.value.profilePictureFile.$error;
    case "criteria":
      return (
        formData.value.criteria.length !==
          helperSignUpStore.campaignData?.criteria.length ||
        formData.value.criteria
          .map((c) => {
            if (c.answerKeys && c.answerKeys.length === 0) {
              return false;
            } else {
              return true;
            }
          })
          .includes(false)
      );
    case "touchpoints":
      return (
        Object.values(formData.value.selectedTouchpoints)
          .map((tp) => {
            return v$.value.selectedTouchpoints[tp.name].$error;
          })
          .filter((t) => t).length > 0 ||
        (!formData.value.acceptEmail && !formData.value.acceptPhone)
      );
    case "resume":
      return; //!!v$.value.resume.$dirty && !v$.value.resume.$invalid;
    default:
      return false;
  }
});

const handleResendCode = async () => {
  await helperSignUpStore.registerPhoneNumber({
    phoneNumber: formData.value.phoneNumber,
  });
};

const handleClickTouchpoint = (touchpoint: ITouchPoint) => {
  const findInSelected = formData.value.selectedTouchpoints[touchpoint.name];

  if (findInSelected) {
    delete formData.value.selectedTouchpoints[touchpoint.name];
  } else {
    formData.value.selectedTouchpoints = {
      ...formData.value.selectedTouchpoints,
      [touchpoint.name]: touchpoint,
    };

    new Promise<void>((resolve) => {
      resolve();
    }).then(() => {
      const input = document.querySelector(
        `#touchpoint-${touchpoint.touchPointId}`
      ) as HTMLInputElement;

      const container = document.querySelector(".touchpoint-container");

      if (input && container) {
        container.scrollTo({
          top: container.scrollHeight + 2000,
          behavior: "smooth",
        });
        input.focus();
      }
    });
  }
};

const selectedTouchpointsToArray = () => {
  return Object.values(formData.value.selectedTouchpoints);
};

watchEffect(async () => {
  init.value = false;

  const campaignUuid = route.params.campaignUuid;

  const isAuth = authStore.jwtToken;

  if (!isAuth) {
    isPublicRegister.value = true;
    await helperSignUpStore.getPublicCampaignData({
      campaignUuid: campaignUuid as string,
    });
  } else {
    await helperSignUpStore.getAuthCampaignData({
      campaignUuid: campaignUuid as string,
    });
  }

  if (helperSignUpStore.campaignData?.redirectTo === "main") {
    router.push({
      name: "relations",
    });
  }

  if (helperSignUpStore.campaignData?.redirectTo === "onboarding") {
    router.push({
      name: "helper-status",
      query: {
        campaign: campaignUuid as string,
      },
    });
  }

  if (
    helperSignUpStore.campaignData?.email &&
    helperSignUpStore.campaignData?.email !== ""
  ) {
    formData.value.email = helperSignUpStore.campaignData?.email;
    disableEmail.value = true;
    v$.value.phoneNumber.$reset();
    currentStep.value = "phone";
  }

  formData.value.phoneNumber =
    helperSignUpStore.campaignData?.phoneNumber || "";
  formData.value.firstName = helperSignUpStore.campaignData?.firstname || "";
  formData.value.lastName = helperSignUpStore.campaignData?.lastname || "";
  formData.value.profilePicture =
    helperSignUpStore.campaignData?.profilePictureUrl || null;
  formData.value.bio = helperSignUpStore.campaignData?.position || "";

  init.value = true;
});

watch(formData.value, (value) => {
  v$.value.$clearExternalResults();
  v$.value.$validate();
});
</script>

<template>
  <div
    v-if="init"
    class="content-form flex bg-background-subtle-hover h-dvh w-full"
  >
    <div
      class="flex-1 flex flex-col md:min-h-0 md:flex-none md:w-[500px] lg:!w-[500px] xl:!w-[600px] md:mx-auto bg-white md:border md:border-border-subtle md:h-fit md:rounded-2xl md:mt-10 xl:mt-20 md:shadow-lg relative overflow-hidden md:mb-20"
    >
      <Header
        v-if="
          currentStep !== 'welcome' &&
          currentStep !== 'start' &&
          currentStep !== 'share' &&
          currentStep !== 'alreadyExist' &&
          currentStep !== 'confirm'
        "
        :step="currentStep"
        @back="handleGoStepBack"
      />

      <HeaderStart
        v-if="
          currentStep === 'welcome' ||
          currentStep === 'start' ||
          currentStep === 'share'
        "
        :organization-logo="
          helperSignUpStore.campaignData?.organizationLogoUrl || ''
        "
        :organization-name="
          helperSignUpStore.campaignData?.organizationName || 'Learnico'
        "
      />

      <div
        :class="`w-full py-10 px-5 flex-1 ${
          currentStep !== 'welcome' &&
          currentStep !== 'start' &&
          currentStep !== 'share' &&
          currentStep !== 'alreadyExist' &&
          currentStep !== 'confirm'
            ? 'mt-16'
            : ''
        }`"
      >
        <Welcome
          v-if="currentStep === 'welcome'"
          :images="helperSignUpStore.campaignData?.helperProfilePictures || []"
        />
        <Start v-if="currentStep === 'start'" />
        <Share
          v-if="currentStep === 'share'"
          :organization-name="
            helperSignUpStore.campaignData?.organizationName || 'Learnico'
          "
        />
        <Email
          v-if="currentStep === 'email'"
          v-model="formData.email"
          :validation="v$.email"
          :disable-email="disableEmail"
        />
        <Confirm v-if="currentStep === 'confirm'" />
        <AlreadyExist v-if="currentStep === 'alreadyExist'" />
        <Phone
          v-if="currentStep === 'phone'"
          v-model="formData.phoneNumber"
          :validation="v$.phoneNumber"
        />
        <Verification
          v-if="currentStep === 'verification'"
          v-model="formData.code"
          :validation="v$.code"
          @resend-code="handleResendCode"
          @go-back="handleGoStepBack()"
        />
        <Name
          v-if="currentStep === 'name'"
          :model-value="{
            firstName: formData.firstName,
            lastName: formData.lastName,
          }"
          @update:model-value="
            (value) => {
              if (value.firstName || value.firstName === '') {
                formData.firstName = value.firstName;
                v$.lastName.$reset();
              }
              if (value.lastName || value.lastName === '') {
                formData.lastName = value.lastName;
                v$.firstName.$reset();
              }
            }
          "
          :validation-first-name="v$.firstName"
          :validation-last-name="v$.lastName.$dirty ? undefined : v$.lastName"
        />
        <Profile
          v-if="currentStep === 'profile'"
          :model-value="{
            profilePicture: formData.profilePictureFile,
            bio: formData.bio,
          }"
          @update:model-value="
            (value) => {
              formData.profilePictureFile = value.profilePicture;
              formData.bio = value.bio;
            }
          "
          :picture-url="formData.profilePictureMediumUrl"
          :validation-profile-picture="v$.profilePictureFile"
          :validation-bio="v$.bio"
        />
        <Criteria
          v-if="currentStep === 'criteria'"
          :criteria="helperSignUpStore.campaignData?.criteria"
          :model-value="formData.criteria"
          @update:model-value="
            (value) => {
              const findCriteria = formData.criteria.find(
                (c) => c.criteriaId === value.criteriaId
              );

              if (findCriteria) {
                if (value.answerKey) {
                  findCriteria.answerKey = value.answerKey;
                } else {
                  findCriteria.answerKeys = value.answerKeys;
                }
              } else {
                value.type === 'singleSelect'
                  ? formData.criteria.push({
                      ...value,
                      answerKey: value.answerKey,
                    })
                  : formData.criteria.push({
                      ...value,
                      answerKeys: value.answerKeys,
                    });
              }
            }
          "
        />
        <Touchpoints
          v-if="currentStep === 'touchpoints'"
          :email="formData.email"
          :phoneNumber="formData.phoneNumber"
          :model-value="{
            acceptEmail: formData.acceptEmail,
            acceptPhone: formData.acceptPhone,
            selectedTouchpoints: formData.selectedTouchpoints,
          }"
          @update:model-value="
            (value) => {
              formData.acceptEmail = value.acceptEmail;
              formData.acceptPhone = value.acceptPhone;
              formData.selectedTouchpoints = value.selectedTouchpoints;
            }
          "
          @touchpoint-click="handleClickTouchpoint"
          :validation="v$.selectedTouchpoints"
        />
        <Resume
          v-if="currentStep === 'resume'"
          :first-name="formData.firstName"
          :bio="formData.bio"
          :selected-touchpoints="formData.selectedTouchpoints"
          :profile-picture="formData.profilePictureMediumUrl"
          :criteria="helperSignUpStore.campaignData?.criteria"
          :selected-critria="formData.criteria"
        />
      </div>
      <div
        v-if="
          currentStep !== 'welcome' &&
          currentStep !== 'start' &&
          currentStep !== 'share'
        "
        class="flex-1"
      ></div>

      <div
        v-if="currentStep !== 'alreadyExist' && currentStep !== 'confirm'"
        :class="`w-full ${
          currentStep === 'welcome' ||
          currentStep === 'start' ||
          currentStep === 'share'
            ? 'px-5 lg:px-20 pb-10'
            : 'px-5 py-10'
        }`"
      >
        <div
          v-if="
            currentStep === 'welcome' ||
            currentStep === 'start' ||
            currentStep === 'share'
          "
          class="flex-1 flex items-end justify-center gap-2 pb-4 md:pb-6"
        >
          <div
            :class="`w-2 h-2 rounded-full ${
              currentStep === 'welcome'
                ? 'bg-background-brand-emphasis'
                : 'bg-background-brand-disabled'
            }`"
          ></div>
          <div
            :class="`w-2 h-2 rounded-full ${
              currentStep === 'start'
                ? 'bg-background-brand-emphasis'
                : 'bg-background-brand-disabled'
            }`"
          ></div>
          <div
            :class="`w-2 h-2 rounded-full ${
              currentStep === 'share'
                ? 'bg-background-brand-emphasis'
                : 'bg-background-brand-disabled'
            }`"
          ></div>
        </div>
        <BaseButton
          size="lg"
          class="w-full"
          :disabled="defineDisableNextStep || disableNext"
          @click="handleGoStepForward"
          >{{ $t("generic.continue") }}</BaseButton
        >
      </div>
    </div>
  </div>
</template>
