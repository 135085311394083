<script lang="ts" setup>
import { computed, onMounted, reactive, ref, watch, watchEffect } from "vue";
import {
  FieldInput,
  FieldCheckbox,
  FieldPhoneNumber,
  BaseButton,
} from "@magma-app/magma-lapilli";
import { useReferralInvite } from "@/api/referrals/use-referral-invite";
import { useRoute } from "vue-router";
import { useCreateHelpee } from "@/api/referrals/use-create-helpee";
import { useResendCode } from "@/api/referrals/use-resend-code";
import { useVerifyCode } from "@/api/referrals/use-verify-code";

const route = useRoute();
const { data: referred, isLoading } = useReferralInvite({
  referralCampaignUuid:
    (route.query.referralCampaignUuid as string) || undefined,
  referredUuid: (route.query.referredUuid as string) || undefined,
  ambassadorRefId: (route.query.ambassadorRefId as string) || undefined,
});
const { mutateAsync, status, data: helpeeData } = useCreateHelpee();
const { mutateAsync: resendCode } = useResendCode();
const { mutateAsync: verifyCode, status: statusVerify } = useVerifyCode();

const currentStep = ref<"info" | "code" | "finish">("info");

const data = ref({
  firstname: referred.value?.referred.firstname || "",
  lastname: referred.value?.referred.lastname || "",
  email: referred.value?.referred.email || "",
  phone: referred.value?.referred.phoneNumber || "",
  accept: false,
  code: "",
});
const error = ref<string | null>(null);
const timerBeforeResend = ref(30);
const timerBeforeRedirect = reactive({ value: 15 });

const validMail = computed(() => {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return emailRegex.test(data.value.email);
});

const handleCreateHelpee = async () => {
  await mutateAsync({
    firstname: data.value.firstname,
    lastname: data.value.lastname,
    email: data.value.email,
    phoneNumber: data.value.phone,
    referredUuid: route.query.referredUuid as string | undefined,
    referralCampaignUuid: route.query.referralCampaignUuid as
      | string
      | undefined,
    ambassadorRefId: route.query.ambassadorRefId as string | undefined,
  });

  currentStep.value = "code";
};

const handleResendCode = async () => {
  await resendCode({
    helpeeId: Number(helpeeData?.value?.id) || 1,
    phoneNumber: data.value.phone,
  });
  timerBeforeResend.value = 30;
};

const handleVerifyCode = async () => {
  await verifyCode({
    referredId: Number(helpeeData?.value?.id) || 1,
    code: data.value.code,
  }).then((d) => {
    if (d.message === "ok") {
      currentStep.value = "finish";
    } else {
      error.value = "Code is invalid";
    }
  });
};

const handleRedirect = () => {
  if (referred.value?.redirectUrl) {
    window.location.href = referred.value.redirectUrl;
  }
};

watchEffect(() => {
  if (currentStep.value === "finish") {
    if (timerBeforeRedirect.value > 0) {
      setTimeout(() => {
        timerBeforeRedirect.value--;
      }, 1000);
    } else {
      handleRedirect();
    }
  }
});

watchEffect(() => {
  if (timerBeforeResend.value > 0) {
    setTimeout(() => {
      timerBeforeResend.value--;
    }, 1000);
  }
});

watchEffect(() => {
  if (referred.value) {
    data.value.firstname = referred.value.referred.firstname;
    data.value.lastname = referred.value.referred.lastname;
    data.value.email = referred.value.referred.email;
    data.value.phone = referred.value.referred.phoneNumber || "";
  }
});

onMounted(() => {
  const input = document.querySelector("input");
  if (input) {
    input.focus();
  }
});
</script>

<template>
  <div
    class="flex flex-col bg-background-subtle lg:justify-center lg:items-center"
  >
    <div
      class="flex-1 flex flex-col md:min-h-0 md:flex-none md:w-[500px] lg:!w-[500px] xl:!w-[600px] md:mx-auto bg-white md:border md:border-border-subtle md:h-fit md:rounded-2xl md:mt-10 xl:mt-20 md:shadow-lg relative overflow-hidden"
    >
      <div
        v-if="!isLoading"
        class="flex w-full border-b border-border-subtle h-16 items-center gap-3 px-6"
      >
        <img
          :src="referred?.organization.logoUrl"
          class="w-8 h-8 rounded-full"
        />
        <p class="text-mgm-txt-sm text-foreground-emphasis font-medium">
          {{ referred?.organization.name }}
        </p>
      </div>
      <div
        v-if="currentStep === 'info'"
        class="p-6 flex flex-col h-[calc(100dvh_-_64px)] md:h-fit"
      >
        <div class="flex-1">
          <p class="text-mgm-txt-lg text-foreground-emphasis font-medium mb-3">
            {{
              $t("referral.invite.title", {
                firstname: referred?.ambassador.firstname,
                organizationName: referred?.organization.name,
              })
            }}
          </p>

          <FieldInput
            v-model="data.firstname"
            :label="$t('referral.invite.firstname')"
            placeholder="Camille"
            class="mb-3"
            required
          />
          <FieldInput
            v-model="data.lastname"
            :label="$t('referral.invite.lastname')"
            placeholder="Durand"
            class="mb-3"
            required
          />
          <FieldInput
            v-model="data.email"
            type="email"
            :label="$t('referral.invite.email')"
            placeholder="camille@example.com"
            class="mb-3"
            required
          />
          <FieldPhoneNumber
            v-model="data.phone"
            label="Phone number"
            description="We'll text you a code to verify your number."
            class="mb-3"
          />
          <FieldCheckbox id="accept" v-model="data.accept" label>
            <span class="text-mgm-txt-sm"
              >{{ $t("referral.invite.accept") }}
              <a
                href="https://www.magma.app/legal-terms"
                target="_blank"
                rel="noreferrer noopener"
                class="underline text-foreground-brand-default"
                >{{ $t("referral.invite.terms") }}</a
              >
              {{ $t("referral.invite.and") }}
              <a
                href="https://www.magma.app/privacy"
                target="_blank"
                rel="noreferrer noopener"
                class="underline text-foreground-brand-default"
                >{{ $t("referral.invite.privacy") }}</a
              ></span
            >
          </FieldCheckbox>
        </div>
        <p class="text-mgm-txt-sm text-foreground-default mt-8">
          {{
            $t("referral.invite.disclaimer", {
              organizationName: referred?.organization.name,
            })
          }}
        </p>
        <BaseButton
          size="lg"
          class="w-full mt-4"
          :disabled="
            data.firstname === '' ||
            data.lastname === '' ||
            data.phone === '' ||
            !data.accept ||
            !validMail ||
            status === 'pending'
          "
          @click="handleCreateHelpee"
          >{{ $t("referral.invite.cta") }}</BaseButton
        >
      </div>
      <div
        v-if="currentStep === 'code'"
        class="p-6 flex flex-col h-[calc(100dvh_-_64px)] md:h-fit"
      >
        <div class="flex-1">
          <p class="text-mgm-txt-lg text-foreground-emphasis font-medium mb-3">
            {{ $t("helperOnboarding.verification.title") }}
          </p>

          <h3 class="text-mgm-txt-sm text-foreground-default mb-6">
            {{ $t("helperOnboarding.verification.subtitle.text") }}(<button
              class="underline"
              @click="currentStep = 'info'"
            >
              {{ $t("helperOnboarding.verification.subtitle.link") }}</button
            >)
          </h3>
          <FieldInput
            id="code"
            type="number"
            autocomplete="one-time-code"
            max-length="6"
            min-length="6"
            v-model="data.code"
            data-cy="code"
            class="mt-4"
            :locale="$i18n.locale"
            :label="$t('helpeeCampaignSignUpView.codeLabel')"
            required
          />

          <p
            v-if="error"
            class="text-mgm-txt-xs text-border-danger-emphasis mt-3"
          >
            {{ error }}
          </p>

          <div class="flex items-center flex-col mt-4">
            <p class="text-foreground-default text-mgm-txt-sm mb-1">
              {{ $t("helpeeCampaignSignUpView.noReceive") }}
            </p>
            <p
              v-if="timerBeforeResend > 0"
              class="text-foreground-default text-mgm-txt-sm font-medium mb-10"
            >
              {{ $t("helpeeCampaignSignUpView.code.resend") }}
              {{ timerBeforeResend }}
              {{ $t("helpeeCampaignSignUpView.code.seconds") }}
            </p>
            <button
              v-else
              class="text-foreground-brand-default text-mgm-txt-sm flex items-center mb-10"
              @click="handleResendCode"
            >
              <BaseIcon
                icon="refresh-cw"
                class="mr-2 -scale-x-100"
                color="rgb(68 76 231)"
              />
              <span class="underline">{{
                $t("helpeeCampaignSignUpView.resendCode")
              }}</span>
            </button>
          </div>
        </div>
        <BaseButton
          size="lg"
          class="w-full mt-4"
          :disabled="data.code.length !== 6 || statusVerify === 'pending'"
          @click="handleVerifyCode"
          >{{ $t("generic.confirm") }}</BaseButton
        >
      </div>
      <div
        v-if="currentStep === 'finish'"
        class="p-6 flex flex-col h-[calc(100dvh_-_64px)] md:h-fit md:py-20"
      >
        <div class="flex-1 flex justify-center items-center flex-col">
          <div
            class="w-12 h-12 bg-[#D1FADF] rounded-full border-8 border-[#ECFDF3] flex items-center justify-center mb-5"
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M20 6L9 17L4 12"
                stroke="#03874C"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <p class="text-mgm-dspl-xs text-foreground-emphasis font-medium mb-1">
            {{ $t("referral.invite.thank.title") }}
          </p>
          <p
            class="w-2/3 mx-auto text-center text-mgm-txt-sm text-foreground-default mb-5"
          >
            {{ $t("referral.invite.thank.text") }}
          </p>
          <p
            v-if="referred?.redirectUrl && referred?.redirectUrl !== ''"
            class="text-mgm-txt-sm text-foreground-default"
          >
            {{ $t("referral.invite.thank.redirect") }}
            {{ timerBeforeRedirect.value }}
          </p>
        </div>
      </div>
    </div>
    <a
      class="mx-auto mt-8 text-center text-mgm-txt-xs font-normal text-foreground-default hover:text-foreground-default focus:text-foreground-default hidden lg:flex items-center"
      href="https://www.magma.app/"
      target="_blank"
    >
      {{ $t("generic.powered") }}
      <img class="ml-2" src="/img/logo_magma_full_bw.svg" />
    </a>
  </div>
</template>
