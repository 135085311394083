const extractUtmOrCleanUrl = (source: string): string => {
  // Check if source is a valid URL
  try {
    const url = new URL(source);

    const utmSource = url.searchParams.get("utm_source");
    const utmMedium = url.searchParams.get("utm_medium");

    if (utmSource) return utmSource;
    if (utmMedium) return utmMedium;

    return url.origin + url.pathname;
  } catch (e) {
    return source;
  }
};

export { extractUtmOrCleanUrl };
